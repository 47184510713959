import React from "react"
import styled from "styled-components"
import Input from "../components/BlogComponent/Input"
import WorkshopCertificate from "../components/Certificates/WorkshopCertificate"
import { useGetCertificatesQuery } from "../components/features/api/authApi"
import certificateGen from "../images/certificateGen.gif"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  width: 100%;
  min-height: 600px;
  height: 100%;
  margin-top: 100px;
  .input_wrapper {
    display: flex;
    width: 60%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .generate_certificate_wrapper {
    grid-area: auto/2/auto/11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      grid-area: auto/1/auto/12;
      margin: 0 1rem;
    }
  }
  .gif_wrapper {
    width: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    @media (max-width: 767px) {
      width: 100%;
      height: auto;
    }
    img {
      position: absolute;
      width: 100%;
      z-index: -1;
      @media (max-width: 767px) {
        position: unset;
      }
    }
  }
  .certicate_wrapper {
    margin-top: 2rem;
  }
`

function GenerateCertificate() {
  const [search, setSearch] = React.useState("")
  const [result, setResult] = React.useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }
  const handleFindElemnt = () => {
    const findElement = data.find((d) => d.uniqueNumber === search)
    setResult(findElement)
  }
  console.log("result", result)

  const { data, isError, isSuccess } = useGetCertificatesQuery()
  return (
    <Wrapper>
      <div className="generate_certificate_wrapper">
        <div className="input_wrapper">
          <Input
            placeholder="Type your'e certifcate number"
            handleSearch={handleSearch}
            search={search}
            handleFindElemnt={handleFindElemnt}
          />
        </div>
        {!result ? (
          <div className="gif_wrapper">
            <img src={certificateGen} alt="generate certificate" />
          </div>
        ) : (
          <div className="certicate_wrapper">
            <WorkshopCertificate result={result} />
          </div>
        )}

        {/* {result.length ? (
          <div className="certicate_wrapper">
            <WorkshopCertificate result={result} />
          </div>
        ) : null} */}
      </div>
    </Wrapper>
  )
}

export default GenerateCertificate
