import React from "react"
import styled from "styled-components"
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  span{
    color: red;
    font-size: 0.85rem;
    margin-top: 1rem;
    position: absolute;
    top: -70%;
    left: 3%;
  }
  .search__container {
    display: flex;
    flex-direction: row;
    background: #f8f8f8;
    box-shadow: 0px 2.70041px 2.70041px rgba(0, 0, 0, 0.1);
    border-radius: 69.3284px;
    /* @media (max-width: 991px) {
      display: none;
    } */
    .search__input {
      width: 100%;
      padding: 20px 24px;
      border: none;
      background-color: transparent;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      color: var(--secondaryColor);

      backface-visibility: hidden;
    }
    .search_icon {
      display: flex;
      width: 70px;
      background-color: var(--purpleColor);
      justify-content: center;
      align-items: center;
      border-radius: 69.3284px;

      button {
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
      }
      button:hover {
        svg {
          color: var(--thirdColor);
        }
      }
      svg {
        color: #fff;
        font-size: 30px;
      }
    }
    .search__input::placeholder {
      color: rgba(87, 87, 86, 0.8) !important;
      text-transform: lowercase;
      letter-spacing: 1.5px;
    }
  }
`

function Input(props) {
  const handleClick = () => {
    props.handleFindElemnt()
  }
  return (
    <Wrapper>
      <div className="search__container">
        <input
          className="search__input"
          type="text"
          placeholder={props.placeholder}
          onChange={(e) => props.handleSearch(e)}
        />

        <div className="search_icon">
          {props.search.length == 4 ? (
            <button onClick={handleClick}>
              <AiOutlineSearch />
            </button>
          ) : (
            <AiOutlineSearch />
          )}
        </div>
      </div>
      {props.search.length > 4 && (
        <span>certificate number length should not be more than 4</span>
      )}
    </Wrapper>
  )
}

export default Input
